<script lang="ts" setup>
import { cn } from '@/lib/utils';
import { useId } from 'radix-vue';
// import type { InjectionKey } from 'vue';
import { provide, useAttrs } from 'vue';
import { FORM_ITEM_INJECTION_KEY } from './injectionKeys';

// export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>;

defineOptions({
  inheritAttrs: false,
});

const id = useId();
provide(FORM_ITEM_INJECTION_KEY, id);

const { class: className, ...rest } = useAttrs();
</script>

<template>
  <div :class="cn('w-full', className ?? '')" v-bind="rest">
    <slot />
  </div>
</template>
